@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'TikTok Sans';
    src: url('./fonts/TikTokSans-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'TikTok Sans';
    src: url('./fonts/TikTokSans-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'TikTok Sans';
    src: url('./fonts/TikTokSans-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

html,
body,
#root {
    height: 100%;
  }
